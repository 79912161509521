import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-plugin-sanity-image"

import Layout from "../components/Layout"
import Meta from "../components/Meta"

import Nav from "../components/Nav"
import MobileMenu from "../components/MobileMenu"
import Footer from "../components/Footer"

import RenderBlock from "../components/RenderBlock"

const StyledPage = styled.div`
  position: relative;
  transition: 0.2s ease-in;
`
const StyledModal = styled.div`
  position: absolute;
  left: 0;
  top: -80px;
  background: var(--black);
  opacity: 0.9;
  width: 100%;
  height: 100vh;
  z-index: 100001;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in;
  .modal {
    width: 350px;
    background: black;
    padding: 20px;
    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        height: 1px;
        opacity: 0.4;
        background-color: white;
      }
      .close {
        font-size: 18px;
        transition: 0.2s ease-in;
        padding: 0 5px;
      }
    }
    .bottom {
      .modal-block {
      }
      p {
        margin-bottom: 5px;
      }
    }
  }
`

const StyledContent = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .name-container {
    padding-top: 100px;
    @media (max-height: 780px) {
      padding-top: 40px;
    }
    @media (max-width: 600px) {
      padding-top: 40px;
    }

    h1,
    .long {
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      font-size: 56px;
      line-height: 75px;
      letter-spacing: 0.5em;
      background: linear-gradient(
        45deg,
        #584870 5%,
        #ed1d69 47.4%,
        #ee786a 98.43%
      );
      background-size: 300% 300%;
      animation: gradient-animation 6s ease infinite;
      background-blend-mode: lighten;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      user-select: none;
      @media (max-width: 768px) {
        animation: unset;
        background-size: unset;
      }
      &.short {
        margin-bottom: 0;
      }
      &.long {
        margin-top: 0;
      }
      @media (max-width: 1250px) {
        font-size: 48px;
      }
      @media (max-width: 1005px) {
        font-size: 40px;
      }
      @media (max-width: 768px) {
        font-size: 30px;
      }
      @media (max-width: 600px) {
        font-size: 22px;
        line-height: 1.5;
      }
    }
  }

  .description-container {
    text-align: center;
    margin-top: 20px;
    h2,
    p {
      filter: drop-shadow(2px 4px 6px #000);
    }
    h2 {
      margin-bottom: 10px;
    }
  }

  .large-logo {
    opacity: 0.4;
    max-width: 600px;
    height: auto;
    width: 100%;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    margin-top: -12%;
    z-index: -1;
    @media (max-height: 780px) {
      max-width: 250px;
      height: auto;
      margin-top: -100px;
    }
    @media (max-width: 600px) {
      margin-top: -80px;
    }
  }
`
const StyledCTA = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
  @media (max-width: 600px) {
    margin: 0px 0 20px 0;
  }
`

export default function Home({ data }) {
  const details = data.details
  const notification = data.notification

  const [isModalActive, setIsModalActive] = useState(
    notification.modalEnabled &&
      typeof window !== "undefined" &&
      !localStorage.getItem(`view-modal-${notification._updatedAt}`)
  )

  const handleClickCloseModal = e => {
    e.preventDefault()

    typeof window !== "undefined" &&
      localStorage.setItem(`view-modal-${notification._updatedAt}`, "true")
    setIsModalActive(false)
  }

  return (
    <StyledPage className="page">
      <Meta title="Home" />
      <Layout>
        <Nav
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          name={details.shortName}
          logo={details.logo}
        />
        <MobileMenu
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
        {isModalActive && (
          <StyledModal className="notification-modal">
            <div className="modal">
              <div className="top">
                <h3 className="title">{notification.title}</h3>
                <a
                  href="/close"
                  className="close"
                  onClick={e => handleClickCloseModal(e)}
                >
                  x
                </a>
              </div>
              <div className="bottom">
                <RenderBlock
                  block={notification.notificationContent}
                  className={"modal-block"}
                />
              </div>
            </div>
          </StyledModal>
        )}
        <StyledContent className="content">
          <div className="name-container">
            <h1 className="short">{details.shortName}</h1>
            <p className="long">{details.longName}</p>
          </div>
          <RenderBlock
            block={details.homepageText}
            className={"description-container"}
          />
          <Image
            alt={details.description}
            className={"large-logo"}
            width={600}
            height={600}
            {...details.logo}
          />
          {details.homepageCta && (
            <StyledCTA>
              <button
                onClick={e => {
                  navigate(`/about-us`)
                }}
              >
                More about us
              </button>
            </StyledCTA>
          )}
        </StyledContent>
        <Footer
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
      </Layout>
    </StyledPage>
  )
}

export const siteDetails = graphql`
  query {
    details: sanityDetails {
      contactIcon {
        ...ImageWithPreview
      }
      logo {
        ...ImageWithPreview
      }
      homepageCta
      phone
      longName
      shortName
      description
      copyrightMessage
      agencyName
      agencyLink
      homepageText {
        _key
        style
        _rawChildren
        _type
        list
        children {
          _key
          _type
          text
          marks
        }
      }
    }
    notification: sanityNotification {
      modalEnabled
      title
      id
      _updatedAt
      notificationContent {
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
        list
        style
      }
    }
  }
`
